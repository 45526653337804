// External Dependencies
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { prestoAdminApiClient } from 'utils/api/apiClient';

export const useGetAllImports = () => {
  return useQuery({
    queryFn: () => prestoAdminApiClient.v1.imports.getAll(),
    queryKey: ['getAllImports'],
  });
};

export const useGetOneImport = (importId: string) => {
  return useQuery({
    queryFn: () =>
      prestoAdminApiClient.v1.imports.getOne({ params: { importId } }),
    queryKey: ['getOneImport', importId],
  });
};

export const useUpdateImport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      variables: Parameters<typeof prestoAdminApiClient.v1.imports.updateOne>[0],
    ) => prestoAdminApiClient.v1.imports.updateOne(variables),
    onSuccess: (response) => {
      queryClient.setQueryData(['getOneImport', response.data.data.import.id], response);
      queryClient.invalidateQueries(['getAllImports']);
    },
  });
};

export const useReplayImport = () => {
  return useMutation({
    mutationFn: (
      variables: Parameters<typeof prestoAdminApiClient.v1.imports.replayImport>[0],
    ) => prestoAdminApiClient.v1.imports.replayImport(variables),
  });
};
