import moment from 'moment';

export const DATE = 'M/D/YYYY';
export const TIME = 'h:mm a';
export const DATE_TIME_AMPM = 'M/D/YYYY, h:mm A';
export const DATE_TIME = 'MMMM Do YYYY, h:mm:ss a';
export const SHORT_DATE_TIME = 'M/D/YY, h:mm:ss a';
export const HUMANIZE_DATE_SHORT = 'MMM D';

// Local Typings
interface FormatDateOptions {
  isUTC?: boolean;
}

// Local Variables
const getPaddedDateValues = (d: Date) => {
  const zeroPad = (value: number) =>
    value.toString().padStart(2, '0');

  const year = d.getFullYear();
  const month = zeroPad(d.getMonth() + 1);
  const date = zeroPad(d.getDate());

  return {
    date,
    month,
    year,
  };
};

export const getFormattedDate = (d: Date) => {
  const {
    date,
    month,
    year,
  } = getPaddedDateValues(d);

  return `${month}/${date}/${year}`;
};

export const getDateFromInput = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  return new Date(dateArg);
};

export const formatDate = (
  date: Date | string | null,
  options?: FormatDateOptions,
) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);

  // If the date does not have a time value
  // Ignore the local time
  const hasTime = typeof date === 'object'
    || (typeof date === 'string' && date.includes(':'));

  const shouldUseUtc = options?.isUTC || !hasTime;

  return shouldUseUtc
    ? moment(d).utc(hasTime).format('l')
    : moment(d).format('l');
};

export const formatDateTime = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);
  return moment(d).format('M/D/YYYY, h:mm:ss a');
};

export const formatDateTimeAmPm = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);
  return moment(d).format(DATE_TIME_AMPM);
};
