// External Dependencies
import { CreateNoteRequest, UpdateNoteRequest } from '@presto-assistant/api_types/api/v1/notes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { prestoApiClient } from 'utils/api/apiClient';

export const useCreateNote = ({
  idRef,
  tableRef,
}: {
  idRef: string;
  tableRef: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      note,
    }: {
      note: CreateNoteRequest['body']['note'];
    }) => prestoApiClient.v1.notes.createNote({
      body: {
        idRef,
        note,
        tableRef,
      },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        'notes', {
          idRef,
          tableRef,
        },
      ]);
    },
  });
};

export const useGetNotes = ({
  idRef,
  tableRef,
}: {
  idRef: string;
  tableRef: string;
}) => {
  return useQuery({
    queryFn: () =>
      prestoApiClient.v1.notes.getNotes({
        params: {
          idRef,
          tableRef,
        },
      }),
    queryKey: [
      'notes', {
        idRef,
        tableRef,
      },
    ],
  });
};

export const useUpdateNote = ({
  id,
  idRef,
  tableRef,
}: {
  id: string;
  idRef: string;
  tableRef: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      note,
    }: {
      note: UpdateNoteRequest['body']['note'];
    }) => prestoApiClient.v1.notes.updateNote({
      body: {
        note,
      },
      params: {
        id,
      },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        'notes', {
          idRef,
          tableRef,
        },
      ]);
    },
  });
};
