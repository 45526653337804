// Internal Dependencies
import { Flex } from 'components/shared';

// Local Dependencies
import MemberSearch from './MemberSearch';

// Component Definition
const Search = (): JSX.Element => {
  return (
    <Flex gap={4}>
      <MemberSearch />
    </Flex>
  );
};

export default Search;
