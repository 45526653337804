// External Dependencies
import { alpha } from '@mui/material/styles';
import { getFullName } from '@presto-assistant/api_types/utils';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import NoteTextOutlineIcon from 'mdi-material-ui/NoteTextOutline';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import { useGetNotes } from 'hooks/api/notes';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useIsOpen } from 'hooks/useIsOpen';
import { APP_NAME, prestoAvatarLogo } from 'utils/constants';

// Local Dependencies
import CreateNoteInput from './CreateNoteInput';
import EditNoteDialog from './EditNoteDialog';
import MessageBlock from './MessageBlock';
import NotesZeroState from './NotesZeroState';

// Local Typings
interface Props {
  idRef: string;
  tableRef: string;
}

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.notes-card': {
    borderRadius: 12,
  },
  '.notes-card-avatar': {
    backgroundColor: theme.palette.stripeBlue['400'],
  },
  '.notes-card-header': {
    backgroundColor: alpha(theme.palette.accentBlue, 0.08),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '.notes-card-title': {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  '.notes-input-container': {
    padding: theme.spacing(0, 1),
  },
  '.system-author-avatar': {
    height: 16,
    marginRight: theme.spacing(1),
    width: 16,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    width: '100%',
  },
  strong: {
    padding: '0.5ch',
  },
}));

const noteContainerHeight = 520;

const systemAuthor = `${APP_NAME} System`;

const systemAuthorElement = (
  <>
    <img
      alt="Presto logo"
      className="system-author-avatar"
      src={prestoAvatarLogo}
    />
    {systemAuthor}
  </>
);

// Component Definition
const Notes = ({
  idRef,
  tableRef,
}: Props): JSX.Element => {
  const [hasLoadedNotes, setHasLoadedNotes] = useState<boolean>(false);
  const [noteIdToEdit, setNoteIdToEdit] = useState<string>('');
  const [noteToEdit, setNoteToEdit] = useState<string>('');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const user = useGetCurrentUser();

  const {
    data: notesData,
  } = useGetNotes({
    idRef,
    tableRef,
  });

  useEffect(() => {
    if (wrapperRef.current) {
      // smooth scroll
      wrapperRef.current.scrollTo({
        behavior: hasLoadedNotes ? 'smooth' : 'auto',
        top: wrapperRef.current.scrollHeight,
      });

      // set a timeout to ensure the scroll has completed
      // this prevents smooth scroll on initial load
      setTimeout(() => {
        setHasLoadedNotes(true);
      }, 1_000);
    }
  }, [hasLoadedNotes, notesData]);

  const {
    handleClose: handleCloseEditNoteDialog,
    handleOpen: handleOpenEditNoteDialog,
    isOpen: isEditNoteDialogOpen,
  } = useIsOpen();

  const handleOpenNoteDialogAndSetNoteToEdit = useCallback((
    note: string,
    noteId: string,
  ) => {
    setNoteToEdit(note);
    setNoteIdToEdit(noteId);
    handleOpenEditNoteDialog();
  }, [handleOpenEditNoteDialog]);

  return (
    <>
      <StyledContainer maxWidth="sm">
        <EnhancedCard className="notes-card">
          <CardHeader
            avatar={(
              <Avatar className="notes-card-avatar">
                <NoteTextOutlineIcon />
              </Avatar>
            )}
            className="notes-card-header"
            title={(
              <Typography
                className="notes-card-title"
                variant="h6"
              >
                Notes
              </Typography>
            )}
          />

          <Box
            ref={wrapperRef}
            sx={(theme) => ({
              height: noteContainerHeight,
              overflow: 'auto',
              padding: theme.spacing(0, 1, 2),
            })}
          >
            {notesData?.data.data.notes.length === 0 ? <NotesZeroState /> : (
              <List dense>
                {notesData?.data.data.notes.map((note, index) => {
                  // Get the previous and next notes (if they exist)
                  const prevNote = index > 0 ? notesData.data.data.notes[index - 1] : null;
                  const nextNote = index < notesData.data.data.notes.length - 1
                    ? notesData.data.data.notes[index + 1]
                    : null;

                  // Determine if current note is outbound
                  const isOutbound = note.authoredByAdminUserId === (user as DB.AuthUser)?.id;

                  // Calculate showTimestamp
                  const showTimestamp = (() => {
                    // Always show timestamp for the first message
                    if (!prevNote) {
                      return true;
                    }

                    // Check if direction changed
                    const prevOutbound = prevNote?.authoredByAdminUserId === (
                        user as DB.AuthUser
                    )?.id;
                    const directionChanged = isOutbound !== prevOutbound;

                    // If direction changed, always show timestamp
                    if (directionChanged) {
                      return true;
                    }

                    // Compare timestamps
                    const currentTime = new Date(note.createdAt).getTime();
                    const prevTime = new Date(prevNote.createdAt).getTime();
                    const diffInMinutes = (currentTime - prevTime) / (1000 * 60);

                    return diffInMinutes > 15;
                  })();

                  // Determine message position within a group
                  const messagePosition = (() => {
                    // If showing timestamp, it's either a single message or first in a new group
                    if (showTimestamp) {
                      // Check if it's part of a group (next message exists and has same direction)
                      if (nextNote) {
                        const nextOutbound = nextNote.authoredByAdminUserId === (
                            user as DB.AuthUser
                        )?.id;
                        const sameDirection = isOutbound === nextOutbound;

                        // Check if next message is within time window
                        const currentTime = new Date(note.createdAt).getTime();
                        const nextTime = new Date(nextNote.createdAt).getTime();
                        const diffInMinutes = (nextTime - currentTime) / (1000 * 60);

                        if (sameDirection && diffInMinutes <= 15) {
                          return 'first'; // First message in a group
                        }
                      }
                      return 'single'; // Not part of a group
                    }

                    // Not showing timestamp means it's part of a group - is it the last one?
                    if (nextNote) {
                      const nextOutbound = nextNote.authoredByAdminUserId === (
                          user as DB.AuthUser
                      )?.id;
                      const sameDirection = isOutbound === nextOutbound;

                      // Check if next message is within time window
                      const currentTime = new Date(note.createdAt).getTime();
                      const nextTime = new Date(nextNote.createdAt).getTime();
                      const diffInMinutes = (nextTime - currentTime) / (1000 * 60);

                      if (sameDirection && diffInMinutes <= 15) {
                        return 'middle'; // Middle message in a group
                      }
                    }

                    return 'last'; // Last message in a group
                  })();

                  return (
                    <MessageBlock
                      author={note.authoredBySystem
                        ? systemAuthorElement
                        : getFullName({
                          firstName: note.authoredByAdminUserFirstName,
                          lastName: note.authoredByAdminUserLastName,
                        })}
                      createdAt={note.createdAt}
                      key={note.id}
                      messagePosition={messagePosition}
                      note={note.note}
                      noteId={note.id}
                      onOpenNoteDialogAndSetNoteToEdit={isOutbound
                        ? handleOpenNoteDialogAndSetNoteToEdit
                        : null}
                      outbound={isOutbound}
                      showTimestamp={showTimestamp}
                      updatedAt={note.updatedAt}
                    />
                  );
                })}
              </List>
            )}
          </Box>

          <Divider />

          <Box className="notes-input-container">
            <CreateNoteInput
              idRef={idRef}
              tableRef={tableRef}
            />
          </Box>
        </EnhancedCard>
      </StyledContainer>

      <EditNoteDialog
        handleClose={handleCloseEditNoteDialog}
        idRef={idRef}
        isOpen={isEditNoteDialogOpen}
        note={noteToEdit}
        noteId={noteIdToEdit}
        tableRef={tableRef}
      />
    </>
  );
};

export default Notes;
