// External Dependencies
import {
  Box,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  BackToButton,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const DistrictsNew: FC = () => {
  const districtsParams = useSelector(tableQueryParams('districts'));

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="All Districts"
          navigateTo={`/districts${districtsParams}`}
        />
      </Box>

      <Subtitle>
        Create a District
      </Subtitle>

      <EnhancedCard
        sx={{
          marginTop: 2,
          padding: 2,
        }}
      >
        <Typography>Coming soon...</Typography>
      </EnhancedCard>
    </>
  );
};

export default DistrictsNew;
