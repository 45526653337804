// External Dependencies
import { CreateNoteRequest } from '@presto-assistant/api_types/api/v1/notes';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

// Internal Dependencies
import { useCreateNote } from 'hooks/api/notes';
import { CustomInput, Flex } from 'components/shared';

// Local Typings
interface Props {
  idRef: string;
  tableRef: string;
}

interface CreateNoteFormValues {
  note: CreateNoteRequest['body']['note'];
}

// Component Definition
const CreateNoteInput = ({
  idRef,
  tableRef,
}: Props): JSX.Element => {
  const {
    mutate: createNote,
  } = useCreateNote({
    idRef,
    tableRef,
  });

  const handleFormikSubmit = useCallback((
    values: CreateNoteFormValues,
    formikHelpers: FormikHelpers<CreateNoteFormValues>,
  ) => {
    createNote({
      note: values.note,
    }, {
      onSuccess: () => {
        formikHelpers.resetForm();
      },
    });
  }, [createNote]);

  return (
    <Formik<CreateNoteFormValues>
      initialValues={{
        note: '',
      }}
      onSubmit={handleFormikSubmit}
    >
      {({
        values,
      }) => (
        <Form>
          <Flex flexWrap="nowrap">
            <CustomInput
              maxRows={4}
              multiline
              name="note"
              placeholder="Type your message..."
              size="small"
            />

            <IconButton
              color="primary"
              disabled={!values.note.trim()}
              sx={{ marginLeft: 1 }}
              type="submit"
            >
              <SendIcon />
            </IconButton>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNoteInput;
