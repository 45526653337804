// External Dependencies
import { FC, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import useTextField from 'hooks/useTextField';
import { EnhancedAlert, SaveButton } from 'components/shared';
import { useDeleteOrganization } from 'gql/mutations/organizations';
import { useNavigate } from 'react-router-dom';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
}

// Component Definition
const DeleteOrganizationDialog: FC<Props> = ({
  isOpen,
  onClose,
  organizationId,
}) => {
  const noteField = useTextField();

  const navigate = useNavigate();

  const [
    deleteOrganization,
    {
      error,
      loading: isDeleting,
    },
  ] = useDeleteOrganization({
    onCompleted: () => navigate('/organizations'),
  });

  const handleClickConfirm = useCallback(() => {
    deleteOrganization({
      variables: {
        input: {
          deletedNote: noteField.value,
          id: organizationId,
        },
      },
    });
  }, [deleteOrganization, noteField.value]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Delete Organization?</DialogTitle>

      <DialogContent>
        <DialogContentText gutterBottom>
          Are you sure you want to delete this organization?
        </DialogContentText>

        <Box marginY={2}>
          <TextField
            {...noteField}
            fullWidth
            label="Note"
            minRows={4}
            multiline
          />
        </Box>

        {error?.graphQLErrors.map(({ message }) => (
          <EnhancedAlert
            key={message}
            severity="error"
            sx={{ mt: 2 }}
          >
            {message}
          </EnhancedAlert>
        ))}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isDeleting}
          onClick={onClose}
        >
          Cancel
        </Button>

        <SaveButton
          isSaving={isDeleting}
          onClick={handleClickConfirm}
          variant="contained"
        >
          Yes, delete
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrganizationDialog;
