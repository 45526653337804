// External Dependencies
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import type { Moment } from 'moment';

// Internal Dependencies
import { updateSubscription } from 'utils/api';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import SubscriptionCard from './SubscriptionCard';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';

// Local Typings
interface Props {
  onRefetchOrganization: () => void;
  subscriptions: GQL.IOrganizationSubscription[];
}

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  },
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
}));

// Component Definition
const SubscriptionsList = ({
  onRefetchOrganization,
  subscriptions,
}: Props): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState(false);
  const [
    subscriptionIdToUpdate,
    setSubscriptionIdToUpdate,
  ] = useState<string | undefined>(undefined);

  const handleSetSelectedDate = useCallback((dateValue: Moment | null) => {
    setSelectedDate(dateValue?.toDate() ?? new Date());
  }, []);

  const {
    handleClose: handleCloseUpdateSubscriptionDialog,
    handleOpen: handleOpenUpdateSubscriptionDialog,
    isOpen: isUpdateSubscriptionDialogOpen,
  } = useIsOpen();

  useEffect(() => {
    if (subscriptionIdToUpdate) {
      handleOpenUpdateSubscriptionDialog();
    }
  }, [handleOpenUpdateSubscriptionDialog, subscriptionIdToUpdate]);

  const handleSetSubscriptionIdToUpdate = useCallback((id: string) => {
    setSubscriptionIdToUpdate(id);
  }, []);

  const handleCloseDialog = useCallback(() => {
    handleCloseUpdateSubscriptionDialog();
    setSubscriptionIdToUpdate(undefined);
    setSelectedDate(undefined);
  }, [handleCloseUpdateSubscriptionDialog]);

  const handleUpdateSubscriptionDetails = useCallback(async () => {
    if (!subscriptionIdToUpdate || !selectedDate) {
      return;
    }

    try {
      setIsUpdating(true);

      await updateSubscription(subscriptionIdToUpdate, {
        extendedGracePeriodDeadline: selectedDate,
      });
    } catch (error) {
      setIsUpdating(false);
      setSelectedDate(undefined);
    } finally {
      onRefetchOrganization();
      setIsUpdating(false);
      setSelectedDate(undefined);
      setSubscriptionIdToUpdate(undefined);
      handleCloseUpdateSubscriptionDialog();
    }
  }, [
    handleCloseUpdateSubscriptionDialog,
    selectedDate,
    subscriptionIdToUpdate,
  ]);

  // Find the extendedGracePeriodDeadline of the subscription that needs an update
  const gracePeriodDeadlineToUpdate = subscriptions.find((sub) =>
    sub.id === subscriptionIdToUpdate)?.extendedGracePeriodDeadline;

  return (
    <>
      <StyledSection className="subscriptions-list">
        {subscriptions.map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            onSetSubscriptionIdToUpdate={handleSetSubscriptionIdToUpdate}
            onlyHasFreeTrial={subscriptions.length === 1}
            subscription={subscription}
          />
        ))}
      </StyledSection>

      <UpdateSubscriptionDialog
        extendedGracePeriodDeadline={gracePeriodDeadlineToUpdate ?? null}
        isOpen={isUpdateSubscriptionDialogOpen}
        isUpdating={isUpdating}
        onClose={handleCloseDialog}
        onSetSelectedDate={handleSetSelectedDate}
        onUpdateSubscriptionDetails={handleUpdateSubscriptionDetails}
        selectedDate={selectedDate}
        subscriptionIdToUpdate={subscriptionIdToUpdate}
      />
    </>
  );
};

export default SubscriptionsList;
