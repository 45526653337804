// External Dependencies
import { alpha, lighten } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CalendarEditIcon from 'mdi-material-ui/CalendarEdit';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
} from 'components/shared';

// Local Dependencies
import SubscriptionsList from './SubscriptionsList';

// Local Typings
interface Props {
  onRefetchOrganization: () => void;
  subscriptions: GQL.IOrganizationSubscription[];
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.subscriptions-card-header': {
    backgroundColor: alpha(theme.palette.accentBlue, 0.1),
  },
  '.subscriptions-card-header-avatar': {
    backgroundColor: lighten(theme.palette.stripeBlue['600'], 0.2),
  },
  '.subscriptions-card-header-title': {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 500,
  },

  marginTop: theme.spacing(3),
}));

// Component Definition
const OrganizationSubscriptionsCard = ({
  onRefetchOrganization,
  subscriptions,
}: Props): JSX.Element => {
  // Sort subscriptions by dateOrdered in descending order, showing newest first
  const sortedSubscriptions = subscriptions.slice().sort((a, b) => {
    return new Date(b.dateOrdered).getTime() - new Date(a.dateOrdered).getTime();
  });

  return (
    <StyledEnhancedCard>
      <CardHeader
        avatar={(
          <Avatar
            aira-label="Subscriptions Info"
            className="subscriptions-card-header-avatar"
          >
            <CalendarEditIcon />
          </Avatar>
        )}
        className="subscriptions-card-header"
        classes={{
          title: 'subscriptions-card-header-title',
        }}
        title="Subscriptions Info"
      />

      <Divider />

      <EnhancedCardContent>
        <SubscriptionsList
          onRefetchOrganization={onRefetchOrganization}
          subscriptions={sortedSubscriptions}
        />
      </EnhancedCardContent>
    </StyledEnhancedCard>
  );
};

export default OrganizationSubscriptionsCard;
