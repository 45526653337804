// External Dependencies
import { useCallback } from 'react';
import { UpdateNoteRequest } from '@presto-assistant/api_types/api/v1/notes';
import { Form, Formik, type FormikHelpers } from 'formik';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

// Internal Dependencies
import { SaveButton, CustomInput, EnhancedAlert } from 'components/shared';
import { useUpdateNote } from 'hooks/api/notes';

// Local Typings
interface Props {
  handleClose: () => void;
  idRef: string;
  isOpen: boolean;
  note: string;
  noteId: string;
  tableRef: string;
}
type UpdateNoteFormValues = UpdateNoteRequest['body'];

// Component Definition
const EditNoteDialog = ({
  handleClose,
  idRef,
  isOpen,
  note,
  noteId,
  tableRef,
}: Props): JSX.Element => {
  const {
    isLoading,
    mutate: updateNote,
  } = useUpdateNote({
    id: noteId,
    idRef,
    tableRef,
  });

  const handleFormikSubmit = useCallback((
    values: UpdateNoteFormValues,
    formikHelpers: FormikHelpers<UpdateNoteFormValues>,
  ) => {
    updateNote({
      note: values.note,
    }, {
      onSuccess: () => {
        formikHelpers.resetForm();
        handleClose();
      },
    });
  }, [handleClose, updateNote]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle>Edit Note</DialogTitle>

      <Formik<UpdateNoteFormValues>
        initialValues={{
          note,
        }}
        onSubmit={handleFormikSubmit}
      >
        {({
          values,
        }) => (
          <Form>
            <DialogContent>
              <CustomInput
                label="Note"
                maxRows={6}
                multiline
                name="note"
                placeholder="Type your message..."
              />

              <Collapse in={!values.note.trim()}>
                <EnhancedAlert
                  severity="warning"
                  sx={{ marginTop: 2 }}
                  title="Note is empty"
                >
                  Please provide some text for this note.
                </EnhancedAlert>
              </Collapse>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>
                Cancel
              </Button>

              <SaveButton
                disabled={!values.note.trim()}
                isSaving={isLoading}
                type="submit"
              >
                Save
              </SaveButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditNoteDialog;
