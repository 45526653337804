// External Dependencies
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { ImportResources } from '@presto-assistant/api_types';
import { CircularProgress } from '@mui/material';

// Internal Dependencies
import { useGetOneImport } from 'hooks/api/imports';
import { BackToButton, DetailCard, Subtitle } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import Notes from 'components/shared/Notes';

// Local Dependencies
import ImportDetailsContent from './ImportDetailsContent';

// Component Definition
const ImportShow = (): JSX.Element => {
  const { id } = useParams();

  const {
    data: importResponse,
  } = useGetOneImport(id ?? '');

  const importsParams = useSelector(tableQueryParams('imports'));

  const importTitle = importResponse?.data.data.import.organizationLabel ?? '';

  const importResource = importResponse
    ? toTitleCase(ImportResources[importResponse.data.data.import.importResourceId])
    : '';

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="All Imports"
          navigateTo={`/imports${importsParams}`}
        />
      </Box>

      <Box mb={2}>
        <Subtitle>Import Details</Subtitle>
      </Box>

      <Box
        display="flex"
        gap={2}
        mt={2}
        sx={{
          flexWrap: {
            lg: 'nowrap',
            xs: 'wrap',
          },
        }}
      >
        <DetailCard
          content={importResponse
            ? <ImportDetailsContent importData={importResponse.data.data} />
            : <CircularProgress />}
          isDistrict
          subheader={importResource}
          title={importTitle}
        />

        {id && (
          <Notes
            idRef={id}
            tableRef="imports"
          />
        )}
      </Box>
    </>
  );
};

export default ImportShow;
