import { PrestoAdminApiClient } from '@presto-assistant/api_types/prestoAdminApiClient';
import { ApiClient } from '@presto-assistant/api_types/apiClient';

export const prestoAdminApiClient = new PrestoAdminApiClient({
  baseUrl: process.env.REACT_APP_API_URL ?? '',
});

export const prestoApiClient = new ApiClient({
  baseUrl: process.env.REACT_APP_API_URL ?? '',
});
