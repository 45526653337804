// External Dependencies
import { alpha } from '@mui/material/styles';
import { useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import Circle1Icon from 'mdi-material-ui/Numeric1CircleOutline';
import Circle2Icon from 'mdi-material-ui/Numeric2CircleOutline';
import Circle3Icon from 'mdi-material-ui/Numeric3CircleOutline';
import Circle5Icon from 'mdi-material-ui/Numeric5CircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import GiftOutlineIcon from 'mdi-material-ui/GiftOutline';
import IconButton from '@mui/material/IconButton';
import FractionOneHalfIcon from 'mdi-material-ui/FractionOneHalf';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface Props {
  onlyHasFreeTrial: boolean;
  onSetSubscriptionIdToUpdate: (subscriptionId: string) => void;
  subscription: GQL.IOrganizationSubscription;
}
type SubscriptionDuration =
  | 'Free Trial'
  | '6 months'
  | '1 year'
  | '2 years'
  | '3 years'
  | '5 years';
interface SubscriptionDurationData {
  icon: JSX.Element;
  subscriptionEndDate: string;
  title: SubscriptionDuration;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.subscription-card': {
    backgroundColor: alpha(theme.palette.accentBlue, 0.1),
  },
  '.subscription-card-avatar': {
    backgroundColor: theme.palette.stripeBlue['400'],
  },
  '.subscription-card-info': {
    alignItems: 'center',
    display: 'flex',
  },
  '.subscription-card-title': {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
}));

const momentDateFormat = 'YYYY-MM-DD';

// Component Definition
const SubscriptionCard = ({
  onSetSubscriptionIdToUpdate,
  onlyHasFreeTrial,
  subscription,
}: Props): JSX.Element => {
  const theme = useTheme();

  const {
    dateOrdered,
    endDate,
    extendedGracePeriodDeadline,
    id: subscriptionId,
    isFreeTrial,
    startDate,
  } = subscription;

  // Use moment to find out the different from the start date to the end date
  // The duration should be in months
  const start = moment(startDate);
  const end = moment(endDate);

  const duration = end.diff(start, 'months');

  // Use the duration to determine the length of the subscription
  // Only check duration if the subscription is not a free trial
  const getSubscriptionDurationData = () => {
    // Set the default value to free trial
    const subscriptionDurationData: SubscriptionDurationData = {
      icon: <GiftOutlineIcon htmlColor={theme.palette.common.white} />,
      // Either the extendedGracePeriodDeadline or 30 days past the dateOrdered
      subscriptionEndDate: extendedGracePeriodDeadline
        ?? moment(dateOrdered).add(30, 'days').format(momentDateFormat),
      title: 'Free Trial',
    };

    if (duration === 6) {
      subscriptionDurationData.icon = (
        <FractionOneHalfIcon htmlColor={theme.palette.common.white} />
      );
      subscriptionDurationData.subscriptionEndDate = moment(dateOrdered)
        .add(6, 'months')
        .format(momentDateFormat);
      subscriptionDurationData.title = '6 months';
    } else if (duration === 12) {
      subscriptionDurationData.icon = (
        <Circle1Icon htmlColor={theme.palette.common.white} />
      );
      subscriptionDurationData.subscriptionEndDate = moment(dateOrdered)
        .add(1, 'year')
        .format(momentDateFormat);
      subscriptionDurationData.title = '1 year';
    } else if (duration === 24) {
      subscriptionDurationData.icon = (
        <Circle2Icon htmlColor={theme.palette.common.white} />
      );
      subscriptionDurationData.subscriptionEndDate = moment(dateOrdered)
        .add(2, 'years')
        .format(momentDateFormat);
      subscriptionDurationData.title = '2 years';
    } else if (duration === 36) {
      subscriptionDurationData.icon = (
        <Circle3Icon htmlColor={theme.palette.common.white} />
      );
      subscriptionDurationData.subscriptionEndDate = moment(dateOrdered)
        .add(3, 'years')
        .format(momentDateFormat);
      subscriptionDurationData.title = '3 years';
    } else if (duration === 60) {
      subscriptionDurationData.icon = (
        <Circle5Icon htmlColor={theme.palette.common.white} />
      );
      subscriptionDurationData.subscriptionEndDate = moment(dateOrdered)
        .add(5, 'years')
        .format(momentDateFormat);
      subscriptionDurationData.title = '5 years';
    }

    return subscriptionDurationData;
  };

  const handlePressUpdate = useCallback(() => {
    onSetSubscriptionIdToUpdate(subscriptionId);
  }, [onSetSubscriptionIdToUpdate, subscriptionId]);

  const {
    icon,
    subscriptionEndDate,
    title,
  } = getSubscriptionDurationData();

  return (
    <StyledEnhancedCard className="subscription-card">
      <CardHeader
        avatar={(
          <Avatar className="subscription-card-avatar">
            {icon}
          </Avatar>
                  )}
        title={(
          <Typography
            className="subscription-card-title"
            variant="h6"
          >
            {title}
          </Typography>
        )}
      />

      <EnhancedCardContent>
        <Box>
          <Box className="subscription-card-info">
            {isFreeTrial ? (
              <>
                <ShowPageDataDisplay
                  label="Activation Date"
                  type="date"
                  value={dateOrdered}
                />
                <ShowPageDataDisplay
                  label="Grace Period Deadline"
                  value={extendedGracePeriodDeadline ?? 'None'}
                />
                {onlyHasFreeTrial && (
                  <IconButton onClick={handlePressUpdate}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                <ShowPageDataDisplay
                  label="Activation Date"
                  type="date"
                  value={dateOrdered}
                />
                <ShowPageDataDisplay
                  label="Subscription End Date"
                  type="date"
                  value={subscriptionEndDate}
                />
              </>
            )}
          </Box>
        </Box>
      </EnhancedCardContent>
    </StyledEnhancedCard>
  );
};

export default SubscriptionCard;
