import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import ToolbarMoreActionsIconMenu, { MoreActionsItem } from './ToolbarMoreActionsIconMenu';

interface ToolbarAction<T> {
  shouldRender?: (row: T) => boolean;
}

interface ActionColumnOptions {
  disabled?: boolean;
}

export type CreateActionColumnAction<T> = ToolbarAction<T> & MoreActionsItem<T>;

export function createDataGridActionsColumn<T extends GridValidRowModel>(
  actions: (CreateActionColumnAction<T>)[],
  options?: ActionColumnOptions,
): GridColDef<T> | null {
  return actions.length ? {
    align: 'center',
    disableColumnMenu: true,
    disableExport: true,
    field: 'Actions',
    filterable: false,
    headerAlign: 'center',
    headerName: '',
    hideable: false,
    maxWidth: 40,
    minWidth: 40,
    pinnable: false,
    renderCell: (params) => {
      const { row } = params;

      const filteredActions = actions.filter((action) =>
        action.shouldRender?.(row) ?? true);

      if (!filteredActions.length) {
        return null;
      }

      return (
        <ToolbarMoreActionsIconMenu<T>
          disabled={options?.disabled}
          moreActions={filteredActions.map(({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            shouldRender,
            ...action
          }) => ({
            ...action,
          }))}
          row={row}
        />
      );
    },
    sortable: false,
  } : null;
}
