// External Dependencies
import camelCase from 'lodash.camelcase';

// Local Typings
interface SearchObject {
  [key: string]: any;
}

const convertDataType = (str: string) => {
  // eslint-disable-next-line no-restricted-globals
  const typeIsNum = !isNaN(Number(str));
  const typeIsBool = str === 'true' || str === 'false';
  if (typeIsNum) {
    return Number(str);
  }
  if (typeIsBool) {
    return str === 'true';
  }

  return str;
};

const parseSearch = <T = Record<string, any>>(str: string): SearchObject => {
  let noQMark;
  if (str === '') {
    return {} as T extends SearchObject ? T : SearchObject;
  }
  if (str[0] === '?') {
    noQMark = str.slice(1, str.length);
  } else {
    noQMark = str;
  }
  const arr = noQMark.split('&');
  const o: SearchObject = {};
  arr.forEach((a) => {
    // split on first '='
    const splitArr = a.split(/=(.+)/);

    const key = splitArr[0];
    const val = decodeURIComponent(splitArr[1]);
    o[camelCase(key)] = convertDataType(val);
  });
  return o as T extends SearchObject ? T : SearchObject;
};

export default parseSearch;
