// External Dependencies
import gql from 'graphql-tag';
import { useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { useMutationEnhanced } from 'hooks';

// Local Typings
interface CreateLicensePaymentByAdminResponse {
  createLicensePaymentByAdmin: GQL.ILicensePayment;
}

interface CreateLicensePaymentByAdminVariables {
  input: GQL.ICreateLicensePaymentByAdminInput;
}

const CREATE_LICENSE_PAYMENT_BY_ADMIN = gql`
  mutation CreateLicensePaymentByAdmin(
    $input: CreateLicensePaymentByAdminInput!
  ) {
    createLicensePaymentByAdmin(input: $input) {
      adminEnteredBy {
        id
        email
        firstName
        lastName
      }
      authorizedBy {
        id
        email
        firstName
        lastName
      }
      checkNumber
      district {
        id
        label
      }
      id
      paymentMethod
      purchaseOrderNumber
      remainingLicenses {
        expiresAt
        id
        licensePayment {
          subtotalInCents
        }
        redeemedAt
        redeemedBy {
          id
          email
        }
        redemptionCode
      }
      subtotalInCents
      taxInCents
    }
  }
`;

export const useCreateLicensePaymentByAdmin = (args: {
  onCompleted: () => void;
}, districtId: string) => {
  const queryClient = useQueryClient();

  return useMutationEnhanced<
    CreateLicensePaymentByAdminResponse,
    CreateLicensePaymentByAdminVariables
  >(
    CREATE_LICENSE_PAYMENT_BY_ADMIN,
    {
      onCompleted: () => {
        queryClient.invalidateQueries(['licenses']);
        queryClient.invalidateQueries([
          'notes', {
            idRef: districtId,
            tableRef: 'districts',
          },
        ]);
        args.onCompleted();
      },
    },
  );
};
