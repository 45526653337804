// External Dependencies
import { useCallback, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';

// Internal Dependencies
import {
  EnhancedAlert,
  SaveButton,
  ShowPageDataDisplay,
} from 'components/shared';

// Local Typings
interface Props {
  extendedGracePeriodDeadline: GQL.IOrganizationSubscription['extendedGracePeriodDeadline'];
  isOpen: boolean;
  isUpdating: boolean;
  onClose: () => void;
  onSetSelectedDate: (dateValue: moment.Moment | null) => void;
  selectedDate: Date | undefined;
  subscriptionIdToUpdate: string | undefined;
  onUpdateSubscriptionDetails: (id: string) => void;
}

// Component Definition
const UpdateSubscriptionDialog = ({
  extendedGracePeriodDeadline,
  isOpen,
  isUpdating,
  onClose,
  onSetSelectedDate,
  onUpdateSubscriptionDetails,
  selectedDate,
  subscriptionIdToUpdate,
}: Props): JSX.Element => {
  // If there is an extended grace period deadline,
  //  update the state value one level higher
  useEffect(() => {
    if (extendedGracePeriodDeadline) {
      onSetSelectedDate(moment(extendedGracePeriodDeadline));
    }
  }, [extendedGracePeriodDeadline, onSetSelectedDate]);

  const handlePressConfirm = useCallback(() => {
    if (subscriptionIdToUpdate) {
      onUpdateSubscriptionDetails(subscriptionIdToUpdate);
    }
  }, [onUpdateSubscriptionDetails, subscriptionIdToUpdate]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
    >
      <DialogTitle>Update Subscription?</DialogTitle>

      <DialogContent>
        <DialogContentText paragraph>
          Choose a new date to extend the grace period deadline for this subscription.
        </DialogContentText>

        <EnhancedAlert
          severity="warning"
          title="Run the scheduled job"
          v3Style
        >
          After completing this update, manually trigger this scheduled job:

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              marginTop: 1.5,
            }}
          >
            <ArrowRightIcon sx={{ marginRight: 1 }} />
            <code>
              Update Organization Subscription Statuses
            </code>
          </Box>

        </EnhancedAlert>

        <Divider sx={{ my: 2 }} />

        <Box mb={2}>
          <Box mb={4}>
            <ShowPageDataDisplay
              label="Current Grace Period Deadline"
              value={extendedGracePeriodDeadline ?? 'None'}
            />
          </Box>

          <DatePicker
            label="New Grace Period Deadline"
            maxDate={moment().endOf('year')}
            minDate={moment()}
            onChange={onSetSelectedDate}
            renderInput={(params) => (
              <TextField
                {...params}
              />
            )}
            value={selectedDate}
            views={['year', 'month', 'day']}
          />
        </Box>
      </DialogContent>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="center"
      >
        <DialogActions>
          <Button
            disabled={isUpdating}
            onClick={onClose}
          >
            Cancel
          </Button>

          <SaveButton
            disabled={!selectedDate}
            isSaving={isUpdating}
            onClick={handlePressConfirm}
            variant="contained"
          >
            Yes, Update
          </SaveButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateSubscriptionDialog;
