// External Dependencies
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridRowId,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
  GridFilterModel,
} from '@mui/x-data-grid-pro';
import { FC, useCallback, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

// Internal Dependencies
import useTextField from 'hooks/useTextField';
import type { NavigateSearchFn } from 'utils/lib/navigate_search';

// Local Dependencies
import AddButton, { AddButtonProps } from '../AddButton';
import {
  Flex,
  RefreshingIcon,
} from '..';
import ToolbarMoreActionsIconMenu from './ToolbarMoreActionsIconMenu';

// Local Typings
export interface EnhancedGridToolbarProps {
  addButtonProps?: AddButtonProps | null;
  gridFilterModel: GridFilterModel;
  isHydrating: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onUpdateParams?: NavigateSearchFn;
  rows: any[] | null;
  search?: string;
  toolbarActions?: IToolbarAction[];
  withSearch?: boolean;
}
export interface IToolbarAction {
  action: () => void;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  text: string;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.bottomToolbarContainer': {
    padding: theme.spacing(0.5, 1.5, 1.5),
  },
  '.searchAndActionsContainer': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    justifyContent: 'flex-end',
  },
  '.topToolbarContainer': {
    padding: theme.spacing(1.5, 1.5, 0.5),
  },

  borderBottom: `1px solid ${theme.palette.grey['300']}`,
  left: 0,
  position: 'sticky',
  right: 0,
}));

const EMPTY_SEARCH_STATE = [''];

// Component Definition
const EnhancedGridToolbar: FC<EnhancedGridToolbarProps & GridToolbarProps> = ({
  addButtonProps,
  gridFilterModel,
  isHydrating,
  onFilter,
  onUpdateParams,
  rows,
  search,
  setFilterButtonEl,
  toolbarActions,
  withSearch,
}) => {
  const navigate = useNavigate();
  const quickFieldProps = useTextField(search);

  const [searchValue] = useDebounce(quickFieldProps.value, 300);

  const apiContext = useGridApiContext();

  const currentApiContext = apiContext.current;

  useEffect(() => {
    const newValue = searchValue ? searchValue?.toString().trim().split(' ') : EMPTY_SEARCH_STATE;

    currentApiContext.setQuickFilterValues(newValue);

    onUpdateParams?.(navigate, { q: searchValue });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>
      currentApiContext.setQuickFilterValues(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApiContext, navigate, onUpdateParams, searchValue]);

  useEffect(() => {
    // I don't know why, but we need the setTimeout to put this at the end of the event loop
    // otherwise, the onFilter does not get called on the first render
    setTimeout(() => {
      onFilter?.(gridFilteredSortedRowIdsSelector(apiContext));
    }, 0);
  }, [apiContext, gridFilterModel, onFilter, rows]);

  const handleClearQuickFilterValue = useCallback(() => {
    quickFieldProps.onReset();
  }, [quickFieldProps]);

  const shouldShowMoreActionsMenu = toolbarActions && toolbarActions.length > 0;

  return (
    <StyledRoot>
      <Flex
        alignItems="flex-start"
        className="topToolbarContainer"
        justifyContent="space-between"
      >
        <Flex>
          {addButtonProps && (
            <AddButton
              {...addButtonProps}
              marginRight={24}
            />
          )}
        </Flex>
        <Flex className="searchAndActionsContainer">
          {isHydrating && <RefreshingIcon sx={{ marginX: 2 }} />}

          {withSearch && (
            <Input
              {...quickFieldProps}
              endAdornment={(
                <InputAdornment
                  position="end"
                  sx={{ width: 30 }}
                >
                  {quickFieldProps.value && (
                  <IconButton
                    onClick={handleClearQuickFilterValue}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  )}
                </InputAdornment>
            )}
              placeholder="Search..."
              size="small"
              startAdornment={(
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
            )}
            />
          )}

          {shouldShowMoreActionsMenu && (
            <ToolbarMoreActionsIconMenu moreActions={toolbarActions} />
          )}
        </Flex>
      </Flex>

      <Box className="bottomToolbarContainer">
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
          <GridToolbarExport
            printOptions={{
              hideToolbar: true,
              includeCheckboxes: true,
            }}
          />
        </GridToolbarContainer>
      </Box>
    </StyledRoot>
  );
};

export default EnhancedGridToolbar;
