// External Dependencies
import React from 'react';

// Local Dependencies
import EnhancedLink from '../../EnhancedLink';

// Local Typings
interface Props {
  messageText: string;
}

// Component Definition
const ParsedNoteText = ({ messageText }: Props): JSX.Element => {
  // Function to detect URLs, bold text, and horizontal rules,
  // and convert them to appropriate elements
  const renderTextWithLinks = (text: string) => {
    // Regular expression to match URLs starting with https
    const urlRegex = /(https:\/\/[^\s]+)/g;
    // Regular expression to match text surrounded by **
    const boldRegex = /\*\*(.*?)\*\*/g;
    // Regular expression to match three hyphens ---
    const hrRegex = /---/g;

    // Split the text by newlines first to handle line breaks
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      // Initialize an array to hold the parts of the line
      const parts: React.ReactNode[] = [];
      let lastIndex = 0;

      // Function to push plain text parts
      const pushPlainText = (start: number, end: number) => {
        const plainText = line.slice(start, end).trim();
        if (plainText) {
          parts.push(
            <span
              className="note-plain-text"
              key={start}
            >
              {plainText}
            </span>,
          );
        }
      };

      // Function to handle matches and push appropriate elements
      const handleMatch = (match: string, offset: number) => {
        // Push the plain text part before the match
        pushPlainText(lastIndex, offset);

        // If the match is a URL, push it as a link
        if (urlRegex.test(match)) {
          parts.push(
            <EnhancedLink
              href={match}
              key={offset}
              openInNewTab
              rootClasses="text-with-link"
            >
              {match}
            </EnhancedLink>,
          );
          // If the match is bold text, push it as a <strong> element
        } else if (boldRegex.test(match)) {
          parts.push(
            <strong key={offset}>
              {match.replace(/\*\*/g, '')}
            </strong>,
          );
          // If the match is three hyphens, push it as an <hr /> element
        } else if (hrRegex.test(match)) {
          parts.push(<hr key={offset} />);
        }

        // Update the last index to the end of the match
        lastIndex = offset + match.length;
      };

      // Iterate over the matches of URLs, bold text, and horizontal rules
      const combinedRegex = new RegExp(`${urlRegex.source}|${boldRegex.source}|${hrRegex.source}`, 'g');
      for (let match = combinedRegex.exec(line); match !== null; match = combinedRegex.exec(line)) {
        handleMatch(match[0], match.index);
      }

      // Push the remaining plain text part after the last match
      pushPlainText(lastIndex, line.length);

      // Check if the next line contains `---`
      const nextLineContainsHr = lines[lineIndex + 1]?.trim() === '---';
      // Check if the current line contains `---`
      const currentLineContainsHr = line.trim() === '---';

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={lineIndex}>
          {parts}
          {lineIndex < lines.length - 1
            && !nextLineContainsHr
            && !currentLineContainsHr
            && <br />}
        </React.Fragment>
      );
    });
  };

  return (
    <span>
      {renderTextWithLinks(messageText)}
    </span>
  );
};

export default ParsedNoteText;
