// External Dependencies
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  EnhancedCardContent,
  PersonListItem,
  Subtitle,
} from 'components/shared';

// Local Typings
interface Props {
  directors: GQL.IOrganization['directors'];
}

// Component Definition
const DirectorList: FC<Props> = ({ directors }) => {
  const hasDirectors = directors.length > 0;

  return (
    <section>
      <Subtitle hasGutterTop>
        Directors
      </Subtitle>

      <EnhancedCard sx={{ marginTop: 2 }}>
        <List>
          {hasDirectors
            ? directors.map((user, i) => (
              <PersonListItem
                divider={i < directors.length - 1}
                key={user.id}
                userData={user}
                variant="director"
              />
            )) : (
              <EnhancedCardContent>
                <Typography>No directors</Typography>
              </EnhancedCardContent>
            )}
        </List>
      </EnhancedCard>
    </section>
  );
};

export default DirectorList;
