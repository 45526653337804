// External Dependencies
import {
  type KeyboardEvent, type MouseEvent, useCallback, useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';

// Local Typings
interface Props {
  note: string;
  noteId: string;
  onOpenNoteDialogAndSetNoteToEdit: ((note: string, noteId: string) => void) | null;
}

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '.icon-button': {
    fontSize: '1.25rem',
    padding: theme.spacing(1),
  },
}));

const anchorOrigin: MenuProps['anchorOrigin'] = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: MenuProps['transformOrigin'] = { horizontal: 'right', vertical: 'top' };

function handleKeyDown(event: KeyboardEvent) {
  if (['Enter', ' '].includes(event.key)) {
    event.stopPropagation();
  }
}

// Component Definition
const MessageBlockMoreActionsMenu = ({
  note,
  noteId,
  onOpenNoteDialogAndSetNoteToEdit,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl'] | null>(null);

  const open = Boolean(anchorEl);

  const handleClickIconButton = useCallback((event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEditNote = useCallback(() => {
    onOpenNoteDialogAndSetNoteToEdit?.(note, noteId);
    handleClose();
  }, [note, noteId, onOpenNoteDialogAndSetNoteToEdit]);

  return (
    <>
      <Tooltip
        key="icon-button-message-block-more-actions"
        placement="bottom-end"
        title="More Actions"
      >
        <div>
          <StyledIconButton
            aria-controls={open ? 'message-block-more-actions-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            aria-label="More actions"
            aria-owns={open ? 'message-block-more-actions-menu' : undefined}
            classes={{
              sizeSmall: 'icon-button',
            }}
            onClick={handleClickIconButton}
            onKeyDown={handleKeyDown}
            size="small"
          >
            <MoreVertIcon fontSize="small" />
          </StyledIconButton>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id="message-block-more-actions-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        <MenuItem
          onClick={handleEditNote}
          onKeyDown={handleKeyDown}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>

          <ListItemText>
            Edit message
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MessageBlockMoreActionsMenu;
