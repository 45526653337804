// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import cloneDeep from 'lodash.clonedeep';

// Local Dependencies
import {
  TableResource,
  TableSelection,
  updateIsPaginatedListDataLoaded,
  updateTableQueryParams,
  updateTableSelections,
} from './actions';

// Local Typings
interface Action<T> {
  payload: Partial<T>;
  type: string;
}
export interface TableState {
  isPaginatedListDataLoaded: boolean;
  tableParams: Record<TableResource, string | undefined>;
  tableSelections: Record<TableResource, TableSelection | undefined>;
}

// Local Variables
const initialSelection: TableSelection = {
  ids: [],
  selectionType: 'SelectedOne',
};

// Reducers
const TABLE_PARAMS_INITIAL_STATE: TableState = {
  isPaginatedListDataLoaded: false,
  tableParams: {
    cronJobs: `?dataGridFilters=${decodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"label","sort":"asc"}]')}`,
    districtLicenses: `?dataGridFilters=${decodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"label","sort":"asc"}]')}`,
    districts: `?dataGridFilters=${decodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"label","sort":"asc"}]')}`,
    feedback: `?${encodeURI('dataGridFilters={"items":[{"field":"status","operator":"is","id":69031,"value":"Open"}],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"submittedAt","sort":"desc"}]')}`,
    imports: `?${encodeURI('dataGridFilters={"items":[{"field":"importStatusId","operator":"not","id":92841,"value":"Complete"},{"field":"importStatusId","operator":"not","id":8666,"value":"Canceled"}],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"createdAt","sort":"desc"}]')}`,
    inventoryVendors: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'label', sort: 'asc' }]))}`,
    organizations: `?dataGridFilters=${decodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"label","sort":"asc"}]')}`,
    stripeWebhookEvents: `?${encodeURI('dataGridFilters={"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"eventDate","sort":"desc"}]')}`,
  },
  tableSelections: {
    cronJobs: cloneDeep(initialSelection),
    districtLicenses: cloneDeep(initialSelection),
    districts: cloneDeep(initialSelection),
    feedback: cloneDeep(initialSelection),
    imports: cloneDeep(initialSelection),
    inventoryVendors: cloneDeep(initialSelection),
    organizations: cloneDeep(initialSelection),
    stripeWebhookEvents: cloneDeep(initialSelection),
  },
};

const isPaginatedListDataLoaded = createReducer(
  TABLE_PARAMS_INITIAL_STATE.isPaginatedListDataLoaded,
  (handleAction) => [
    handleAction(updateIsPaginatedListDataLoaded, (state, { payload }: Action<TableState['isPaginatedListDataLoaded']>) =>
      payload),
  ],
);
const tableParams = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableParams,
  (handleAction) => [
    handleAction(updateTableQueryParams, (state, { payload }: Action<TableState['tableParams']>) => ({
      ...state,
      ...payload,
    })),
  ],
);
const tableSelections = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableSelections,
  (handleAction) => [
    handleAction(updateTableSelections, (state, { payload }: Action<TableState['tableSelections']>) => ({
      ...state,
      ...payload,
    })),
  ],
);

export default combineReducers<TableState>({
  isPaginatedListDataLoaded,
  tableParams,
  tableSelections,
});
