// External Dependencies
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

// Internal Dependencies
import { BackToButton } from 'components/shared';
import { useGetOrganization } from 'gql/queries/organization';
import { useIsPrestoOwner } from 'hooks/useIsPrestoOwner';
import Notes from 'components/shared/Notes';

// Local Dependencies
import { tableQueryParams } from 'state/table/selectors';
import DirectorList from './DirectorList';
import OrganizationDangerZone from './OrganizationDangerZone';
import OrganizationInfo from './OrganizationInfo';
import OrganizationSubscriptionsCard from './OrganizationSubscriptions/OrganizationSubscriptionsCard';

// Component Definition
const OrganizationShow = (): JSX.Element => {
  const { id } = useParams();

  const {
    data,
    refetch: refetchOrganization,
  } = useGetOrganization(id);

  const organizationsParams = useSelector(tableQueryParams('organizations'));

  const isPrestoOwner = useIsPrestoOwner();

  return (
    <>
      <Box mb={2}>
        <BackToButton
          context="All Organizations"
          navigateTo={`/organizations${organizationsParams}`}
        />
      </Box>

      {data?.organization && (
        <>
          <OrganizationInfo organization={data.organization} />

          <DirectorList directors={data.organization.directors} />

          {id && (
            <OrganizationSubscriptionsCard
              onRefetchOrganization={refetchOrganization}
              subscriptions={data.organization.subscriptions}
            />
          )}

          {id && (
            <Box sx={{ marginTop: 2 }}>
              <Notes
                idRef={id}
                tableRef="organizations"
              />
            </Box>
          )}

          {isPrestoOwner && (
            <OrganizationDangerZone
              orgId={data.organization.id}
              orgName={data.organization.label}
            />
          )}
        </>
      )}
    </>
  );
};

export default OrganizationShow;
