// External Dependencies
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled, { useTheme } from 'styled-components';
import clsx from 'clsx';
import type { AlertProps } from '@mui/material/Alert';

// Local Typings
interface Props extends AlertProps {
  action?: ReactNode;
  children: any;
  isAlertOpen?: boolean;
  onClose?: () => void;
  title?: AlertProps['title'];
  v3Style?: boolean;
}

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.v3': {
    // Small nod towards the Material Design v3
    borderRadius: '20px',
  },
  border: `1px solid ${theme.palette.grey[300]}`,
}));

// Component Definition
const EnhancedAlert = ({
  action,
  children,
  isAlertOpen,
  onClose,
  title,
  v3Style,
  ...otherProps
}: Props): JSX.Element => {
  const theme = useTheme();

  const actionElements = [action];

  const closeActionElement = (
    <StyledIconButton
      aria-label="close"
      key="close-button"
      onClick={onClose}
      size="small"
    >
      <CloseIcon fontSize="small" />
    </StyledIconButton>
  );

  if (onClose) {
    actionElements.push(closeActionElement);
  }

  const alertElement = (
    <StyledAlert
      action={actionElements}
      className={clsx(v3Style ? 'v3' : '')}
      {...otherProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  );

  return onClose
    ? (
      <Collapse
        in={isAlertOpen}
        timeout={theme.transitions.duration.shortest}
      >
        {alertElement}
      </Collapse>
    ) : alertElement;
};

export default EnhancedAlert;
