// External Dependencies
import styled from 'styled-components';

// Local Variables'
const StyledFooter = styled.footer(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.5rem',
  },
  color: theme.palette.text.secondary,
  fontSize: 12,
  padding: '16px 0px',
  textAlign: 'center',
}));

// Component Definition
const Footer = (): JSX.Element => {
  return (
    <StyledFooter>
      &copy; {new Date().getFullYear()} Presto Assistant LLC.{' '}
      All Rights Reserved.
    </StyledFooter>
  );
};

export default Footer;
