export * from './styles';
export const TOKEN_KEY = 'token';

const SUPPORT_EMAIL = 'support@presto-assistant.com';
export const CONTACT_INFO = {
  MAILTO_SUPPORT: `mailto:${SUPPORT_EMAIL}`,
  SUPPORT_EMAIL,
};

export const APP_NAME = 'Presto Assistant';
export const SHORT_APP_NAME = 'Presto';

export const IS_PRODUCTION = process.env.REACT_APP_APPLICATION_ENVIRONMENT === 'production';

export const prestoAvatarLogo = 'https://res.cloudinary.com/presto-assistant/image/upload/t_avatar/v1644498698/logo_3x_afmgzg.png';
