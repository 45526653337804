// External Dependencies
import { alpha } from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import styled from 'styled-components';

// Internal Dependencies
import { Flex } from 'components/shared';

// Local Variables
const NOTE_CONTAINER_HEIGHT = 520;

const StyledRoot = styled(Flex)(({ theme }) => ({
  '.zero-state-icon': {
    color: alpha(theme.palette.grey[400], 0.8),
    height: 96,
    width: 96,
  },
  '.zero-state-text': {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
    marginTop: theme.spacing(2.5),
    maxWidth: '80%',
    textAlign: 'center',
  },
  alignContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: NOTE_CONTAINER_HEIGHT,
  justifyContent: 'center',
  padding: 0,
  width: '100%',
}));

// Component Definition
const NotesZeroState = (): JSX.Element => {
  return (
    <StyledRoot>
      <SpeakerNotesOffIcon
        className="zero-state-icon"
        fontSize="large"
      />

      <DialogContentText
        className="zero-state-text"
        component="div"
      >
        No messages yet
      </DialogContentText>
    </StyledRoot>
  );
};

export default NotesZeroState;
