// External Dependencies
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Internal Dependencies
import { EnhancedCard, Flex } from 'components/shared';
import { formatDateTimeAmPm } from 'utils';
import useHover from 'hooks/useHover';

// Local Dependencies
import ParsedNoteText from './ParsedNoteText';
import MessageBlockMoreActionsMenu from './MessageBlockMoreActionsMenu';

// Local Typings
type MessagePosition = 'single' | 'first' | 'middle' | 'last';

interface Props {
  author: string | React.ReactNode;
  createdAt: string;
  note: string;
  noteId: string;
  onOpenNoteDialogAndSetNoteToEdit: ((note: string, noteId: string) => void) | null;
  outbound: boolean;
  showTimestamp: boolean;
  messagePosition?: MessagePosition;
  updatedAt?: string;
}

interface StyledRootProps {
  $outbound: boolean;
}

// Local Variables
const defaultNoteBorderRadius = 12;

const StyledRoot = styled(Flex)<StyledRootProps>(({ $outbound, theme }) => ({
  '&.no-timestamp': {
    paddingTop: 0,
  },
  // Base styling
  flexDirection: $outbound ? 'row-reverse' : 'row',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),

  // Single message (standard styling)
  // eslint-disable-next-line sort-keys
  '&.single-in-group .message-text-container': {
    borderRadius: defaultNoteBorderRadius,
  },

  // First message in a group - fully rounded top, less rounded bottom
  // eslint-disable-next-line sort-keys
  '&.first-in-group': {
    marginBottom: 1, // Reduce space after the first message
    paddingBottom: 1,
  },
  '&.first-in-group .message-text-container': {
    // More rounded on top, less rounded on bottom
    borderBottomLeftRadius: $outbound ? defaultNoteBorderRadius : 4,
    borderBottomRightRadius: $outbound ? 4 : defaultNoteBorderRadius,
    borderTopLeftRadius: defaultNoteBorderRadius,
    borderTopRightRadius: defaultNoteBorderRadius,
  },

  // Middle messages in a group
  '&.middle-in-group': {
    marginBottom: 1,
    marginTop: 1,
    paddingBottom: 1,
    paddingTop: 1,
  },
  '&.middle-in-group .message-text-container': {
    borderBottomLeftRadius: $outbound ? defaultNoteBorderRadius : 4,
    borderBottomRightRadius: $outbound ? 4 : defaultNoteBorderRadius,
    borderTopLeftRadius: $outbound ? defaultNoteBorderRadius : 4,
    borderTopRightRadius: $outbound ? 4 : defaultNoteBorderRadius,
  },

  // Last message in a group - less rounded top, fully rounded bottom
  // eslint-disable-next-line sort-keys
  '&.last-in-group': {
    marginTop: 1,
    paddingTop: 1,
  },
  '&.last-in-group .message-text-container': {
    // Less rounded on top, more rounded on bottom
    borderBottomLeftRadius: defaultNoteBorderRadius,
    borderBottomRightRadius: defaultNoteBorderRadius,
    borderTopLeftRadius: $outbound ? defaultNoteBorderRadius : 4,
    borderTopRightRadius: $outbound ? 4 : defaultNoteBorderRadius,
  },

  '.author-container': {
    '.author-name': {
      display: 'flex',
      fontWeight: theme.typography.fontWeightBold,
    },
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: $outbound ? 'flex-end' : 'flex-start',
    padding: theme.spacing(0, 0.5),
  },
  '.edited-text': {
    color: $outbound
      ? alpha(theme.palette.common.white, 0.8)
      : theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  '.message-container': {
    alignSelf: $outbound ? 'flex-end' : 'flex-start',
    maxWidth: 'min(75%, 548px)',
    width: 'fit-content',
  },
  '.message-text': {
    // Adjust the EnhancedLink styles
    '& .text-with-link': {
      '&:hover': {
        background: $outbound
          ? theme.palette.prestoPrimaryLight
          : theme.palette.link,
      },
    },

    alignSelf: $outbound ? 'flex-end' : 'flex-start',
    color: $outbound
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  '.message-text-and-actions-container': {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: $outbound ? 'flex-end' : 'flex-start',
  },
  '.message-text-container': {
    backgroundColor: $outbound
      ? alpha(theme.palette.prestoPrimaryMain, 0.95)
      : theme.palette.grey[100],
    padding: '6px 12px 8px',
  },
  '.message-text-spacer': {
    paddingRight: theme.spacing(1),
  },
  '.more-actions-container': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 32,
    visibility: 'hidden',
    width: 32,
  },
  '.visible': {
    visibility: 'visible',
  },
}));

// Component Definition
const MessageBlock = ({
  author,
  createdAt,
  messagePosition = 'single',
  note,
  noteId,
  onOpenNoteDialogAndSetNoteToEdit,
  outbound,
  showTimestamp,
  updatedAt,
}: Props): JSX.Element => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const isEdited = createdAt !== updatedAt;

  return (
    <StyledRoot
      $outbound={outbound}
      className={clsx(
        'message-block',
        !showTimestamp && 'no-timestamp',
        `${messagePosition}-in-group`,
      )}
      component="li"
    >
      <Box className="message-container">
        {showTimestamp && (
          <Flex className="author-container">
            <Typography
              className="author-name"
              variant="caption"
            >
              {author}
            </Typography>

            <Typography
              sx={{ color: 'textSecondary' }}
              variant="caption"
            >
              {formatDateTimeAmPm(new Date(createdAt))}
            </Typography>
          </Flex>
        )}

        <Box
          className="message-text-and-actions-container"
          ref={hoverRef}
        >
          {outbound && (
            <div className="more-actions-container">
              <div
                className={clsx(
                  'more-actions-content',
                  isHovered && 'visible',
                )}
              >
                <MessageBlockMoreActionsMenu
                  note={note}
                  noteId={noteId}
                  onOpenNoteDialogAndSetNoteToEdit={onOpenNoteDialogAndSetNoteToEdit}
                />
              </div>
            </div>
          )}

          <EnhancedCard className="message-text-container">
            <Typography
              className={clsx(
                'message-text',
                isEdited && 'message-text-spacer',
              )}
              component="span"
              variant="body2"
            >
              <ParsedNoteText messageText={note} />
            </Typography>

            {isEdited && (
              <Typography
                className="edited-text"
                component="span"
                variant="body2"
              >
                (edited)
              </Typography>
            )}
          </EnhancedCard>
        </Box>
      </Box>
    </StyledRoot>
  );
};

export default MessageBlock;
