/* eslint-disable sort-keys */
// External Dependencies
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridFilterModel,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRowId,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import { TableResource } from 'state/table/actions';
import { NavigateSearchFn, navigateSearch } from 'utils/lib/navigate_search';
import { useParsedSearch } from 'hooks/useParsedSearch';
import { isMobileScreenSize } from 'state/selectors';

// Local Dependencies
import { AddButtonProps } from '../AddButton';
import { useUpdateParams } from './hooks';
import EnhancedGridToolbar, { EnhancedGridToolbarProps, IToolbarAction } from './EnhancedGridToolbar';
import TableDataGridZeroResultsState from './TableDataGridZeroResultsState';

// Local Typings
interface Props {
  addButtonProps?: AddButtonProps | null;
  autoHeight?: DataGridProProps['autoHeight'];
  checkboxSelection?: DataGridProProps['checkboxSelection'];
  clickRowTo?: (id: string) => string;
  columns: GridColDef[];
  components?: DataGridProProps['components'];
  componentsProps?: DataGridProProps['componentsProps'];
  experimentalFeatures?: DataGridProProps['experimentalFeatures'];
  getRowId?: DataGridProProps['getRowId'];
  hideCheckAll?: boolean;
  leftPinnedColumns?: string[];
  loading?: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onSelectionModelChange?: DataGridProProps['onRowSelectionModelChange'];
  onUpdateParams?: NavigateSearchFn;
  params?: string; // Some "picker" tables send their own params
  persistColumnVisibility?: boolean;
  processRowUpdate?: DataGridProProps['processRowUpdate'];
  rows: any[] | null;
  selectionModel?: DataGridProProps['rowSelectionModel'];
  tableResource?: TableResource;
  toolbarActions?: IToolbarAction[];
  withSearch?: boolean;
  zeroStateMessage?: string;
}

// Local Variables
const StyledRoot = styled.div(({ theme }) => ({
  '.MuiDataGrid-root': {
    '& .MuiDataGrid-cell.bold': {
      fontWeight: 'bold',
    },

    '& .MuiDataGrid-columnHeader': {
      fontWeight: 500,
    },

    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
  },

  '.tableWrapper': {
    flexGrow: 1,
    height: '100%', // Needed for Safari < v15 to correctly display table data
  },

  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
}));

// Avoid an 'X' for the false boolean cell. This emdash is a better visual indicator.
const BooleanCellFalseIcon = () => <> </>;

const dataGridFilterKey = 'dataGridFilters';
const dataGridSortKey = 'dataGridSort';

// Component Definition
const TableDataGrid = ({
  addButtonProps,
  autoHeight,
  checkboxSelection,
  clickRowTo,
  columns,
  components,
  componentsProps,
  experimentalFeatures,
  getRowId,
  hideCheckAll,
  leftPinnedColumns,
  loading,
  onFilter,
  onSelectionModelChange,
  onUpdateParams = navigateSearch,
  params,
  persistColumnVisibility = true,
  processRowUpdate,
  rows,
  selectionModel,
  tableResource,
  toolbarActions,
  withSearch,
  zeroStateMessage,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const [localRows, setLocalRows] = useState(rows);

  const isMobileScreen = useSelector(isMobileScreenSize);

  const [filteredRowIds, setFilteredRowIds] = useState<GridRowId[]>([]);

  const handleFilter = useCallback((rowIds: GridRowId[]) => {
    setFilteredRowIds(rowIds);
    onFilter?.(rowIds);
  }, [onFilter]);

  const handleClickRow = useCallback((path: string) => {
    navigate(path);
  }, [navigate]);

  const hasLocalRows = Boolean(localRows?.length);

  useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  const columnLocalStorageKey = `${tableResource}-columns`;
  const pinnedColumnsLocalStorageKey = `${tableResource}-pinned-columns`;

  const handleChangeColumnVisibility = useCallback((model: GridColumnVisibilityModel) => {
    if (!isMobileScreen && persistColumnVisibility) {
      localStorage.setItem(columnLocalStorageKey, JSON.stringify(model));
    }
  }, [columnLocalStorageKey, isMobileScreen, persistColumnVisibility]);

  const handleChangePinnedColumns = useCallback((model: GridPinnedColumns) => {
    if (isMobileScreen) {
      return;
    }

    // Don't persist the checkbox column
    const leftColumns = model?.left?.filter((column: any) => column !== '__check__');

    const pinnedColumnsToPersist = {
      left: leftColumns,
      right: model?.right ?? [],
    };

    if (persistColumnVisibility) {
      localStorage.setItem(
        pinnedColumnsLocalStorageKey,
        JSON.stringify(pinnedColumnsToPersist),
      );
    }
  }, [persistColumnVisibility, pinnedColumnsLocalStorageKey]);

  // We grab the row values and pass them along
  //  to the onRowClick callback function
  const handleRowClick = ({ row }: GridRowParams) => {
    if (!clickRowTo) {
      return undefined;
    }

    const path = clickRowTo(row.id);

    return handleClickRow(path);
  };

  const { search } = useLocation();

  const searchString = params ?? search;
  const searchParams = useParsedSearch(searchString);

  // We only care about this on mount
  const initialFilters = useMemo(
    () => {
      const dataGridFilters = searchParams[dataGridFilterKey];

      return dataGridFilters ? JSON.parse(dataGridFilters) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialSearchParam = useMemo(
    () => {
      const searchParam = searchParams.q as string | undefined;

      return searchParam ?? undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialSort = useMemo(
    () => {
      const dataGridSort = searchParams[dataGridSortKey];

      return dataGridSort ? JSON.parse(dataGridSort) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [
    gridFilterModel,
    setGridFilterModel,
  ] = useState<GridFilterModel>(initialFilters);
  const [
    gridSortModel,
    setGridSortModel,
  ] = useState<GridSortModel>(initialSort);

  const initialColumns = useMemo(() => {
    const localStorageColumnsValue = localStorage.getItem(columnLocalStorageKey);

    if (localStorageColumnsValue) {
      return JSON.parse(localStorageColumnsValue);
    }

    return {};
  }, [columnLocalStorageKey]);

  const initialPinnedColumns = useMemo(() => {
    if (isMobileScreen) {
      return [];
    }

    const localStoragePinnedColumnsValue = localStorage.getItem(
      pinnedColumnsLocalStorageKey,
    );

    if (localStoragePinnedColumnsValue) {
      return JSON.parse(localStoragePinnedColumnsValue);
    }

    // For users that do not have custom pinned columns,
    //  default to the left pinned columns passed in by each table.
    if (leftPinnedColumns) {
      return {
        left: leftPinnedColumns,
        right: ['Actions'],
      };
    }

    return [];
  }, [isMobileScreen, leftPinnedColumns, pinnedColumnsLocalStorageKey]);

  const handleChangeFilters = useCallback((model: GridFilterModel) => {
    const filterModel = encodeURIComponent(JSON.stringify(model));

    setGridFilterModel(model);

    onUpdateParams(
      navigate,
      {
        [dataGridFilterKey]: filterModel,
      },
    );
  }, [navigate, onUpdateParams]);

  const handleChangeSort = useCallback((model: GridSortModel) => {
    const sortModel = encodeURIComponent(JSON.stringify(model));

    setGridSortModel(model);

    onUpdateParams(
      navigate,
      {
        [dataGridSortKey]: sortModel,
      },
    );
  }, [navigate, onUpdateParams]);

  const flexColumns = useMemo<GridColDef[]>(() => {
    const localColumns = columns.map<GridColDef>((column) => ({
      flex: 1,
      minWidth: 120,
      // custom sort to allow empty values at end when asc
      sortComparator: (v1: any, v2: any) => {
        if (typeof v1 === 'undefined' && typeof v2 === 'undefined') {
          return 0;
        }

        if (!Number.isNaN(Number(v1 || 0)) && !Number.isNaN(Number(v2 || 0))) {
          return Number(v1 ?? Infinity) - Number(v2 ?? Infinity);
        }

        if (typeof v1 === 'string' || typeof v2 === 'string') {
          return (v1 as string || 'zz').localeCompare(v2 as string || 'zzz');
        }

        if (typeof v1 === 'boolean' && typeof v2 === 'boolean') {
          return Number(v1) - Number(v2);
        }

        // TODO:When we add dates to a table later, we need to handle sorting formatted date strings
        if (typeof v1 === 'object' && typeof v2 === 'object' && moment(v1).isValid() && moment(v2).isValid()) {
          return (v1 as Date).getDate() - (v2 as Date).getDate();
        }

        return 1;
      },
      ...column,
    }));

    return localColumns;
  }, [columns]);

  useUpdateParams(tableResource, searchString);

  const renderNoResultsOverlay = useCallback(() => {
    return <TableDataGridZeroResultsState message={zeroStateMessage ?? 'No data'} />;
  }, [zeroStateMessage]);

  const slotsValues = {
    NoResultsOverlay: TableDataGridZeroResultsState,
    NoRowsOverlay: renderNoResultsOverlay,
    Toolbar: EnhancedGridToolbar,
    ...components,
  };

  const initialState = useMemo(() => ({
    columns: {
      columnVisibilityModel: initialColumns,
    },
    filter: {
      filterModel: initialFilters,
    },
    pinnedColumns: {
      left: [
        GRID_CHECKBOX_SELECTION_COL_DEF.field,
        ...(initialPinnedColumns?.left ?? [])],
      right: initialPinnedColumns?.right ?? [],
    },
    sorting: {
      sortModel: initialSort,
    },
  }), [
    initialColumns,
    initialFilters,
    initialSort,
    initialPinnedColumns,
  ]);

  const slotsPropsValues = useMemo(() => ({
    toolbar: {
      addButtonProps,
      filteredIds: filteredRowIds,
      gridFilterModel,
      gridSortModel,
      isHydrating: loading,
      onFilter: handleFilter,
      onUpdateParams,
      rows,
      search: initialSearchParam,
      showQuickFilter: true,
      toolbarActions,
      withSearch,
    } as EnhancedGridToolbarProps,
    ...componentsProps,
  }), [
    addButtonProps,
    componentsProps,
    filteredRowIds,
    gridFilterModel,
    initialSearchParam,
    loading,
    handleFilter,
    toolbarActions,
    withSearch,
  ]);

  const hasFilters = Boolean(
    gridFilterModel?.items.length || gridFilterModel?.quickFilterValues?.some(Boolean),
  );

  const handleStateChange = useCallback((state: any) => {
    if (state.pinnedColumns.right) {
      // keep the "Actions" column at the end
      const actionsIndex = (state.pinnedColumns.right as string[])
        .findIndex((column) => column === 'Actions');
      state.pinnedColumns.right.splice(actionsIndex, 1);
      state.pinnedColumns.right.push('Actions');
    }
  }, []);

  return (
    <StyledRoot>
      <div className="tableWrapper">
        <DataGridPro
          autoHeight={hasLocalRows && autoHeight}
          checkboxSelection={checkboxSelection}
          columns={flexColumns}
          density="standard"
          disableRowSelectionOnClick
          experimentalFeatures={{
            ...experimentalFeatures,
            ariaV7: true,
          }}
          filterModel={gridFilterModel}
          getRowId={getRowId}
          hideFooter={!hasLocalRows}
          initialState={initialState}
          loading={loading && !hasLocalRows}
          localeText={{
            toolbarExport: 'Reports',
          }}
          onColumnVisibilityModelChange={handleChangeColumnVisibility}
          onFilterModelChange={handleChangeFilters}
          onPinnedColumnsChange={handleChangePinnedColumns}
          onRowClick={handleRowClick}
          onRowSelectionModelChange={loading
            ? undefined
            : onSelectionModelChange}
          onSortModelChange={handleChangeSort}
          onStateChange={handleStateChange}
          processRowUpdate={processRowUpdate}
          rowBuffer={10}
          rowSelectionModel={loading ? undefined : selectionModel}
          rowThreshold={10}
          rows={rows ?? []}
          slotProps={{
            ...slotsPropsValues,
            toolbar: slotsPropsValues.toolbar,
            noResultsOverlay: slotsPropsValues.noResultsOverlay,
            noRowsOverlay: slotsPropsValues.noRowsOverlay,
          }}
          slots={{
            ...slotsValues,
            booleanCellFalseIcon: BooleanCellFalseIcon,
            toolbar: slotsValues.Toolbar,
            noResultsOverlay: hasFilters
              ? slotsValues.NoResultsOverlay : slotsValues.NoRowsOverlay,
            noRowsOverlay: hasFilters
              ? slotsValues.NoResultsOverlay : slotsValues.NoRowsOverlay,
          }}
          sortModel={gridSortModel}
          sx={hideCheckAll ? {
            // inspired by https://github.com/mui/mui-x/issues/1904#issuecomment-862827127
            '& .MuiDataGrid-columnHeaderCheckbox > div': {
              display: 'none',
            },
          } : {}}
        />
      </div>
    </StyledRoot>
  );
};

export default TableDataGrid;
