// External Dependencies
import Card, { type CardProps } from '@mui/material/Card';
import { forwardRef } from 'react';

// Component Definition
const EnhancedCard = forwardRef<HTMLDivElement, CardProps>(({
  children,
  ...otherProps
}, ref): JSX.Element => (
  <Card
    {...otherProps}
    ref={ref}
    variant="outlined"
  >
    {children}
  </Card>
));

export default EnhancedCard;
